import React from 'react';
import { DemoTemplate } from 'src/templates';

import { Demo } from 'src/components/Demo';

const metaData = {
  layout: 'demo',
  'meta-title': 'Plaid Demo',
  'meta-description': 'Explore Plaid and Plaid Link',
};

export default () => {
  return (
    <DemoTemplate {...metaData}>
      <Demo />
    </DemoTemplate>
  );
};
