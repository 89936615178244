import { useTrack } from 'src/contexts/AnalyticsContext';
import { DEMO_EVENTS } from 'src/lib/trackingEvents';

export const useDemoTracking = () => {
  const track = useTrack();
  const trackLinkStart = function (product, linkToken) {
    track({
      type: DEMO_EVENTS.LINK_START,
      payload: {
        product: product,
        linkToken: linkToken,
      },
    });
  };

  const trackLinkSuccess = function (product, linkToken) {
    track({
      type: DEMO_EVENTS.LINK_SUCCESS,
      payload: {
        product: product,
        linkToken: linkToken,
      },
    });
  };

  const trackLinkExit = function (product, linkToken) {
    track({
      type: DEMO_EVENTS.LINK_EXIT,
      payload: {
        product: product,
        linkToken: linkToken,
      },
    });
  };

  const trackLaunchExternal = function (product) {
    track({
      type: DEMO_EVENTS.LAUNCH_EXTERNAL,
      payload: {
        product: product,
      },
    });
  };

  return {
    trackLinkStart,
    trackLinkSuccess,
    trackLinkExit,
    trackLaunchExternal,
  };
};
