import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import Image from 'src/components/Image';
import { fetchLinkToken, findEntity } from 'src/lib/utils';
import { useDeviceSize } from 'src/hooks';

import { Button, SelectWithController, Checkbox } from 'src/components/Inputs';
import { countries, languages, products } from './DemoConstants';
import { useDemoTracking } from './DemoAnalytics';

const defaultOptions = {
  countryCode: findEntity(countries, 'US'),
  language: findEntity(languages, 'en'),
  product: findEntity(products, 'af'),
  authTypeSelect: false,
};

const getProductsOptions = (countryCode, isMobile) => {
  return products.filter((x) => {
    const isExcludedByCountry = x.excludedCountries.includes(countryCode);
    const isExcludedByMobile = isMobile && x.url;
    return !isExcludedByCountry && !isExcludedByMobile;
  });
};

const getControlValues = ({ options, param }) => {
  if (param) {
    const option = findEntity(options, param);
    return {
      defaultValue: option,
      controlledValue: option,
    };
  }
  return {};
};

const onLinkSuccess = ({
  token,
  metadata,
  selectedProduct,
  selectedCountryCode,
  selectedLanguage,
  paymentId,
}) => {
  // All of the redirect destinations begin with `window.location.pathname`
  // to account for PR preview builds where the page path is `/commit-sha/demo`
  // as well as local and production where the page path is `/demo`
  const demoPath = window.location.pathname;

  let newLocation =
    demoPath + `/accounts/?public_token=${token}&product=${selectedProduct}`;
  if (selectedProduct === 'assets') {
    newLocation += '&account_id=null';
  }
  // For the deposit switch product, it does not make sense to redirect
  // to any form of the current demo landing page, which is centered
  // on account connection, when deposit switch is focused on
  // transactionally switching the account's direct deposit. Because it
  // would require a relatively large amount of effort to change the
  // landing page to accommodate deposit switch, we will just redirect
  // back to the demo page for deposit switch right now.
  if (selectedProduct === 'deposit_switch') {
    return;
  }
  if (selectedProduct === 'income_verification') {
    newLocation =
      demoPath +
      `/income_verification/?public_token=${token}&product=income_verification`;
  }
  if (selectedProduct === 'bank_income') {
    newLocation =
      demoPath + `/bank_income/?public_token=${token}?product=bank_income`;
  }
  if (selectedProduct === 'identity') {
    newLocation =
      demoPath + `/identity/?public_token=${token}&product=${selectedProduct}`;
  }
  if (selectedProduct === 'payment_initiation') {
    const payment_id = paymentId || '';
    newLocation = demoPath + `/payment_initiation/?payment_id=${payment_id}`;
  }
  if (selectedProduct === 'auth') {
    if (
      metadata.accounts[0].verification_status === 'pending_manual_verification'
    ) {
      newLocation =
        demoPath +
        `/microdeposits/?public_token=${token}&product=${selectedProduct}&country=${selectedCountryCode}&language=${selectedLanguage}`;
    } else {
      // Call auth/get since we're not dealing with a microdeposits item
      newLocation =
        demoPath + `/auth/?public_token=${token}&product=${selectedProduct}`;
    }
  }
  // The simple concatentation above makes an assumption about trailing slashes
  // This normalizes the final string to have no double slashes
  window.location = newLocation.replaceAll('//', '/');
};

export const Demo = () => {
  const device = useDeviceSize();
  const isMobile = device.isSmall;
  const Plaid = typeof window !== 'undefined' && window?.Plaid;
  const {
    trackLinkStart,
    trackLinkSuccess,
    trackLinkExit,
    trackLaunchExternal,
  } = useDemoTracking();
  // Set default form values based on URL
  const router = useRouter();
  // These will initially be empty due to https://nextjs.org/docs/advanced-features/automatic-static-optimization
  // but will eventually have values when the client renders
  const { countryCode, language, product, force_flink } = router.query;
  const defaultCountry = useMemo(() => {
    return (findEntity(countries, countryCode) || defaultOptions.countryCode)
      .value;
  }, [countryCode]);
  const defaultLanguage = useMemo(() => {
    return (findEntity(languages, language) || defaultOptions.language).value;
  }, [language]);
  const getDefaultProduct = useCallback(
    (countryCode) => {
      const availableProducts = getProductsOptions(countryCode, isMobile);
      return (
        findEntity(availableProducts, product) ||
        findEntity(availableProducts, defaultOptions.product?.value) ||
        availableProducts[0]
      ).value;
    },
    [isMobile, product],
  );
  const defaultProduct = getDefaultProduct(defaultCountry);
  const [isAuthTypeSelectEnabled, setIsAuthTypeSelectEnabled] = useState(
    defaultOptions.authTypeSelect,
  );

  // State related to the form
  const { register, watch, handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      selectedProduct: defaultProduct,
      selectedCountryCode: defaultCountry,
      selectedLanguage: defaultLanguage,
    },
  });

  const watchSelectedProduct = watch('selectedProduct');
  const watchSelectedCountryCode = watch('selectedCountryCode');
  const watchSelectedLanguage = watch('selectedLanguage');

  // Handle the `useRouter` hydrating case, which causes the default values
  // to change
  useEffect(() => {
    reset({
      selectedProduct: defaultProduct,
      selectedCountryCode: defaultCountry,
      selectedLanguage: defaultLanguage,
    });
  }, [defaultProduct, defaultCountry, defaultLanguage, reset]);

  // Update product if the country changes
  // if selected product does not exist in newly selected country, default to first available
  useEffect(() => {
    const productsOptions = getProductsOptions(
      watchSelectedCountryCode,
      isMobile,
    );
    if (
      !productsOptions.find((x) => {
        return x.value === watchSelectedProduct;
      })
    ) {
      const defaultProduct = getDefaultProduct(
        watchSelectedCountryCode,
        isMobile,
      );
      setValue('selectedProduct', defaultProduct);
    }
  }, [
    watchSelectedCountryCode,
    watchSelectedProduct,
    setValue,
    getDefaultProduct,
    isMobile,
  ]);

  // State related to Link
  const linkHandlerRef = useRef(null);
  const linkTokenRefForAnalytics = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const selectedProductObj = useMemo(() => {
    return products.find((product) => {
      return product.value === watchSelectedProduct;
    });
  }, [watchSelectedProduct]);

  // open Link
  const launchDemo = useCallback(() => {
    if (selectedProductObj?.url) {
      // Redirect to the Coast demo instead!
      trackLaunchExternal(selectedProductObj.value);
      window.open(selectedProductObj.url, '_blank');
    } else {
      if (!linkHandlerRef.current) return;
      trackLinkStart(
        selectedProductObj.value,
        linkTokenRefForAnalytics.current,
      );
      linkHandlerRef.current.open();
    }
  }, [selectedProductObj]);

  // Eagerly fetch the Link Token -- unless the demo has a "url" property
  useEffect(() => {
    if (
      !watchSelectedProduct ||
      !watchSelectedCountryCode ||
      !watchSelectedLanguage ||
      !Plaid
    ) {
      return;
    }

    // Clean up any existing handler
    if (linkHandlerRef.current) {
      linkHandlerRef.current.destroy();
      linkHandlerRef.current = null;
    }
    // Reset loading state
    setIsLoading(true);

    // Check if the selected product has a URL; if so, skip fetching the link token
    if (selectedProductObj?.url) {
      setIsLoading(false);
      return;
    }

    let handler;
    // This handles the case where the inputs change
    // while a previous token is still being fetched
    let ignore = false;
    let controller = new AbortController();

    const fetchData = async () => {
      const linkTokenCreateConfig = {
        selectedCountryCode: watchSelectedCountryCode,
        selectedLanguage: watchSelectedLanguage,
        selectedProduct: watchSelectedProduct,
        isAuthTypeSelectEnabled,
      };
      const linkTokenResp = await fetchLinkToken(linkTokenCreateConfig, {
        signal: controller.signal,
      });
      if (!linkTokenResp || ignore) {
        // escape hatch for link token fetch failure
        return;
      }
      const token = linkTokenResp.linkToken;

      // create a Link handler with the provided inputs
      const configs = {
        token,
        onSuccess: (token, metadata) => {
          trackLinkSuccess(
            linkTokenCreateConfig.selectedProduct,
            linkTokenRefForAnalytics.current,
          );
          onLinkSuccess({
            token,
            metadata,
            selectedProduct: linkTokenCreateConfig.selectedProduct,
            selectedCountryCode: linkTokenCreateConfig.selectedCountryCode,
            selectedLanguage: linkTokenCreateConfig.selectedLanguage,
            paymentId: linkTokenResp?.options?.payment_initiation?.payment_id,
          });
        },
        onExit: async function (err) {
          trackLinkExit(
            linkTokenCreateConfig.selectedProduct,
            linkTokenRefForAnalytics.current,
          );
          if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
            // if the item_add_token becomes invalid, recreate the link
            // handler with a new item_add_token
            handler.destroy();
            handler = undefined;
            linkHandlerRef.current = null;
          }
        },
      };

      handler = Plaid.create(configs, {
        enableFlexLink: force_flink,
      });
      linkHandlerRef.current = handler;
      linkTokenRefForAnalytics.current = token;
      setIsLoading(false);
    };
    fetchData();
    return () => {
      controller.abort();
      ignore = true;
      handler && handler.destroy();
    };
    // Including 'isLoading' would cause this effect to run endlessly because 'isLoading' is updated within the effect.
    // Since 'isLoading' is only updated as a result of this effect and not used inside it, it's safe to omit it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Plaid,
    force_flink,
    isAuthTypeSelectEnabled,
    watchSelectedCountryCode,
    watchSelectedLanguage,
    watchSelectedProduct,
    selectedProductObj,
  ]);

  const ctaCopy = 'Launch Demo';

  return (
    <div className='background-page-wrapper'>
      <div className='grid-container section-container'>
        <div className='grid-x align-justify'>
          <div className='cell small-12 page-header-breakpoint-6 large-5 page-header-content'>
            <form
              data-testid='demo-launch'
              id='demoLaunch'
              onSubmit={handleSubmit(launchDemo)}
            >
              <div className='grid-x'>
                <div className='cell small-10 page-header-breakpoint-12'>
                  <h1 className='page-header-header h1'>Plaid Link Demo</h1>
                  <h4 className='regular page-header-subheader'>
                    Explore Plaid and Plaid Link
                  </h4>
                </div>

                <div className='cell medium-12 large-12 page-header-breakpoint-12'>
                  <SelectWithController
                    setValue={setValue}
                    register={register}
                    control={control}
                    name='selectedCountryCode'
                    id='country'
                    label='Country'
                    options={countries}
                    {...getControlValues({
                      options: countries,
                      param: watchSelectedCountryCode,
                    })}
                  />
                  <SelectWithController
                    setValue={setValue}
                    register={register}
                    control={control}
                    name='selectedLanguage'
                    id='language'
                    label='Language'
                    options={languages}
                    {...getControlValues({
                      options: languages,
                      param: watchSelectedLanguage,
                    })}
                  />
                  <SelectWithController
                    setValue={setValue}
                    register={register}
                    control={control}
                    name='selectedProduct'
                    id='product'
                    label='Product'
                    options={getProductsOptions(
                      watchSelectedCountryCode,
                      isMobile,
                    )}
                    {...getControlValues({
                      options: getProductsOptions(
                        watchSelectedCountryCode,
                        isMobile,
                      ),
                      param: watchSelectedProduct,
                    })}
                  />
                  {watchSelectedProduct === 'auth' &&
                    watchSelectedCountryCode === 'US' && (
                      <Checkbox
                        key='enableAuthTypeCheckbox'
                        id='enableAuthTypeCheckbox'
                        name='selectedEnableAuthTypeCheckbox'
                        onChange={() => {
                          return setIsAuthTypeSelectEnabled(
                            !isAuthTypeSelectEnabled,
                          );
                        }}
                        value={isAuthTypeSelectEnabled}
                      >
                        Enable{' '}
                        <a href='/docs/auth/coverage/same-day#auth-type-select'>
                          Auth Type Select
                        </a>
                      </Checkbox>
                    )}
                </div>
              </div>
              <Button
                type='submit'
                className='cell medium-12 small-12 button'
                fullWidth
                disabled={isLoading}
              >
                {ctaCopy}
              </Button>
            </form>
          </div>
          <div className='cell small-12 medium-6 page-header-bg-wrapper'>
            <div className='page-header-bg'>
              <Image
                src='/assets/img/hero/demo-hero.png'
                alt=''
                width='1260'
                height='1028'
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};
