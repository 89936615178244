export const countries = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
  },
  {
    label: 'Ireland',
    value: 'IE',
  },
  {
    label: 'Germany',
    value: 'DE',
  },
  {
    label: 'Netherlands',
    value: 'NL',
  },
  {
    label: 'France',
    value: 'FR',
  },
  {
    label: 'Spain',
    value: 'ES',
  },
];

export const languages = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Danish',
    value: 'da',
  },
  {
    label: 'Dutch',
    value: 'nl',
  },
  {
    label: 'Estonian',
    value: 'et',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Latvian',
    value: 'lv',
  },
  {
    label: 'Lithuanian',
    value: 'lt',
  },
  {
    label: 'Norwegian',
    value: 'no',
  },
  {
    label: 'Polish',
    value: 'pl',
  },
  {
    label: 'Romanian',
    value: 'ro',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'Swedish',
    value: 'sv',
  },
];

export const products = [
  {
    label: 'Account Funding and Verification',
    value: 'af',
    url: 'https://plaid.coastdemo.com/share/6786ccc5a048a5f1cf748cb5?zoom=100',
    excludedCountries: [],
  },
  {
    label: 'Pay by Bank, powered by Plaid Transfer',
    value: 'pbb',
    url: 'https://plaid.coastdemo.com/share/66faf2bfb0feffe54db38114?zoom=100',
    excludedCountries: ['GB', 'CA', 'IE', 'DE', 'FR', 'ES', 'NL'],
  },
  {
    label: 'Credit and Underwriting',
    value: 'credit',
    url: 'https://plaid.coastdemo.com/share/66fb0a180582208ffa82103e?zoom=100',
    excludedCountries: ['GB', 'CA', 'IE', 'DE', 'FR', 'ES', 'NL'],
  },
  {
    label: 'Transactions',
    value: 'transactions',
    excludedCountries: [],
  },
  {
    label: 'Layer',
    value: 'layer',
    url: 'https://plaid.coastdemo.com/share/6717daecf618361b93d583f5?zoom=100',
    excludedCountries: [],
  },
  {
    label: 'Auth',
    value: 'auth',
    excludedCountries: [],
  },
  {
    label: 'Assets',
    value: 'assets',
    excludedCountries: [],
  },
  {
    label: 'Identity',
    value: 'identity',
    excludedCountries: [],
  },
  {
    label: 'Investments',
    value: 'investments',
    excludedCountries: ['GB', 'IE', 'DE', 'FR', 'ES', 'NL'],
  },
  {
    label: 'Liabilities',
    value: 'liabilities',
    excludedCountries: ['GB', 'IE', 'DE', 'FR', 'ES', 'NL'],
  },
  {
    label: 'Payroll and Document Income',
    value: 'income_verification',
    excludedCountries: ['GB', 'CA', 'IE', 'DE', 'FR', 'ES', 'NL'],
  },
  {
    label: 'Payment Initiation',
    value: 'payment_initiation',
    excludedCountries: ['US', 'CA'],
  },
  {
    label: 'Bank Income',
    value: 'bank_income',
    excludedCountries: ['GB', 'CA', 'IE', 'DE', 'FR', 'ES', 'NL'],
  },
];
